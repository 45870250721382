import moment from "moment";
import pluralize from "pluralize";
import React from "react";

function isValidBoolean(data) {
  if (isNullOrUndefined(data)) {
    return false;
  }

  return typeof data === "boolean";
}

export function checkPermissionsOld(permissions, needed, customer) {
  let allowed = false;

  if (permissions === undefined || permissions === null || permissions.length === 0) {
    // temporarily disable  return false;
    return false;
  }

  if (customer === -1) {
    Object.keys(permissions).forEach((cus) => {
      permissions[cus].forEach((per) => {
        if (needed < 99) {
          if (per >= needed && per < 99) {
            allowed = true;
          }
        } else if (per === needed) {
          allowed = true;
        }
      });
    });
  } else if (customer >= 0) {
    Object.keys(permissions).forEach((cus) => {
      if (cus === -1) {
        permissions[cus].forEach((per) => {
          if (needed < 99) {
            if (per >= needed && per < 99) {
              allowed = true;
            }
          } else if (per === needed) {
            allowed = true;
          }
        });
      } else if (cus === customer) {
        permissions[cus].forEach((per) => {
          if (needed < 99) {
            if (per >= needed && per < 99) {
              allowed = true;
            }
          } else if (per === needed) {
            allowed = true;
          }
        });
      }
    });
  } else if (permissions[customer] !== undefined) {
    if (permissions[customer] !== undefined) {
      for (let x in permissions[customer]) {
        if (needed < 99) {
          if ((permissions[customer][x] >= needed) & (permissions[customer][x] < 99)) {
            allowed = true;
          }
        } else if (permissions[customer][x] === needed) {
          allowed = true;
        }
      }
    }
  }
  // console.log('checkPermissions',permissions,needed, customer,allowed)
  return allowed;
}

export function checkPermissions(permissions, needed, customer) {
  if (permissions === undefined || permissions === null || permissions.length === 0) {
    // temporarily disable  return false;
    return true;
  }

  // const systemWide = permissions.filter(    (permission) => permission.customer_id === -1 && permission.id >= needed  );
  const customerSpecific = permissions.filter((permission) => {
    if (needed < 99) {
      return permission.customer_id === customer && permission.id >= needed && permission.id < 99;
    }
    return permission.customer_id === customer && permission.id === needed;
  });

  return customerSpecific.length > 0;
}

export function getCustomersAsArray(store) {}

export const levels = {
  view: 1,
  edit: 2,
  admin: 3,
  enroll: 100,
  manufacture: 102,
  manufactureConfig: 103,
  addUser: 200,
  changeProtocol: 201,
  systemAdmin: 300
};

// export const user_roles = {
//   System_Admin:0,
//   Rikatec_Admin:1,
//   Rikatec_Viewer:2,
//   Customer_Admin:3,
//   Customer_Viewer:4,
//   Installer:5,
//   Manufacture:7
//
// }

/**
 * Internal functions
 */

function isNullOrUndefined(data) {
  return data === null || data === undefined;
}

function titleCase(stringText) {
  var sentence = stringText.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
}

function validString(data) {
  if (isNullOrUndefined(data)) {
    return "";
  }

  return data;
}

function validNumberOrDefault(data, defaultValue = 0) {
  return isValidNumber(data) ? data : defaultValue;
}

function setMomentThresholds() {
  moment.relativeTimeThreshold("s", 59);
  moment.relativeTimeThreshold("m", 59);
  moment.relativeTimeThreshold("h", 23);
  moment.relativeTimeThreshold("d", 28);
}

/**
 * External Functions
 */

export async function getEntityLogo(entityAcronym) {
  console.log({ entityAcronym });
  if (entityAcronym === "BLM") {
    return await import("../images/blm-logo.png");
  }
  return await import("../images/riiot-logo.png");
}

export function getCategoryTotals({ data, fieldName, colorScheme, defaultUndefinedLabel = "Unknown", defaultColour = "#90EE90" }) {
  const fieldTotals = data.reduce(function (accumulator, item) {
    const field = getFieldFromObject(item, fieldName) || defaultUndefinedLabel;
    const value = accumulator[field]?.value || 1;

    if (!accumulator[field]) {
      accumulator[field] = {
        label: field,
        value: value
      };

      if (colorScheme && colorScheme[field]) {
        accumulator[field].colour = colorScheme[field];
      } else {
        accumulator[field].colour = defaultColour;
      }
    } else {
      accumulator[field].value++;
    }

    return accumulator;
  }, {});

  const sortOrder = Object.keys(colorScheme);
  if (sortOrder) {
    const sortedTotals = {};
    //Sort by color
    sortOrder.forEach(function (key) {
      if (fieldTotals.hasOwnProperty(key)) {
        sortedTotals[key] = fieldTotals[key];
      }
    });

    //add those that are not sorted
    Object.keys(fieldTotals).forEach(function (key) {
      if (!Object.keys(sortedTotals).includes(key)) {
        sortedTotals[key] = fieldTotals[key];
      }
    });
    return Object.values(sortedTotals);
  }
  return Object.values(fieldTotals);

  function getFieldFromObject(object, propertyName) {
    
    var parts = propertyName.split(".");
    let result = object;
    for (var i = 0; i < parts.length; i++) {
      result = result[parts[i]];
    }

    return result;
  }
}

export function getCategoryInfoTotals({ data, fieldName, defaultColour = "#CFCFCF" }) {
  const fieldTotals = data.reduce(function (accumulator, item) {
    const field = getFieldFromObject(item, fieldName) || {label: "Unknown", value: 0, order: 9999};
    const value = accumulator[field.label]?.value || 1;


    if (!accumulator[field.label]) {
      accumulator[field.label] = {
        label: field.label,
        order: field.order,
        value: value
      };

      if (field.colour) {
        accumulator[field.label].colour = field.colour;
      } else {
        accumulator[field.label].colour = defaultColour;
      }
    } else {
      accumulator[field.label].value++;
    }

    return accumulator;
  }, {});

  return Object.values(fieldTotals).sort((a, b) => a.order - b.order);

}

export function getFieldFromObject(object, propertyName) {
  var parts = propertyName.split(".");
  let result = object;
  for (var i = 0; i < parts.length; i++) {
    result = result[parts[i]];
  }

  return result;
}

/* Table options */
export function getTableRenderSizePerPageOptionsDropDown(props, recordCount) {
  const roundedUpCount = Math.ceil(recordCount / 100) * 100;
  let options = [10, 25, 50, 75, 100, 250, 500, 750];
  let lastGreaterThanValue = null;
  const minOption = recordCount > 10 ? Math.ceil(recordCount / 25) * 25 : 10;

  if (roundedUpCount > options[options.length - 1]) {
    options = options.filter((option) => option < minOption || option >= minOption);
    options.push(roundedUpCount);
  } else {
    options = options.filter((option) => {
      if (lastGreaterThanValue === null) {
        lastGreaterThanValue = option > minOption ? option : null;
        return option <= minOption || option > minOption;
      }
      return false;
    });
  }

  return (
    <div className="btn-group">
      {options.map((n, idx) => {
        const isActive = n === Number(props.currSizePerPage) ? "active" : null;
        let optionName = n;
        if (roundedUpCount === n && idx + 1 === options.length) {
          optionName = "All";
        }

        return (
          <button key={idx} type="button" className={`btn btn-info ${isActive}`} onClick={() => props.changeSizePerPage(n)}>
            {optionName}
          </button>
        );
      })}
    </div>
  );
}

export function tableCellDateFormatter(cell) {
  if (!cell) {
    return "";
  }
  return `${moment(cell).format("lll") ? moment(cell).format("lll") : moment(cell).format("lll")}`;
}


export function toBoolean(data) {
  if (typeof data === "undefined") {
    return false;
  }
  if (typeof data === "object" && isNullOrUndefined(data)) {
    return false;
  }
  if (typeof data === "string" && isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number" && isNullOrUndefined(data)) {
    return false;
  }
  if (typeof data === "boolean" && isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number" && isValidNumber(Number(data))) {
    return Number(data) > 0;
  }

  if (typeof data === "boolean" && isValidBoolean(data)) {
    return data;
  }

  if (typeof data === "string" && isValidString(data)) {
    return stringToBinary(data);
  }
  return false;
}

export function isNotNullOrUndefined(data) {
  if (data === undefined) {
    return false;
  } else if (data === null) {
    return false;
  }
  return true;
}

export function stringToBinary(data, defaultValue = false) {
  if (isValidString(data)) {
    return data === "1" || data.toLowerCase() === "true";
  }

  return defaultValue;
}


export function setupFullName(...names) {
  let fullName = "";

  if (isValidArrayWithData(names)) {
    names.forEach((name) => {
      if (isNotNullOrUndefined(name)) {
        fullName = `${fullName} ${name}`.trim();
      }
    });
  }
  //console.log(`full name:${fullName};`, names);
  return fullName;
}

export function isValidNumber(data) {
  if (isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number") {
    return true;
  }
  if (!isNaN(data)) {
    return true;
  }

  return false;
}
export function isValidString(data) {
  if (isNullOrUndefined(data)) {
    return false;
  }

  return typeof data === "string";
}

export function isValidArrayWithData(data) {
  if (isNullOrUndefined(data)) {
    //console.assert(false, "we got to 183", data);
    return false;
  }

  if (Array.isArray(data)) {
    return data.length > 0;
  }

  return false;
}

export function isValidEmailAddress(emailAddress) {
  const regX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regX.test(emailAddress);
}

export function splitAlphaNumeric(alphaNumeric) {
  if (isNullOrUndefined(alphaNumeric)) {
    return "";
  }

  if (typeof alphaNumeric !== "string") {
    return alphaNumeric;
  }

  const stringArray = alphaNumeric.match(/[a-zA-Z]+|[0-9]+/g);

  return stringArray ? stringArray.join(" ") : alphaNumeric;
}

export function lastArrayElement(data) {
  if (isValidArrayWithData(data)) {
    return data[data.length - 1];
  }
}

export function validatedPrimaryKeys(...keys) {
  let isValidated = false;
  let isAlreadyInvalid = false;

  if (isValidArrayWithData(keys)) {
    keys.forEach((key) => {
      if (isAlreadyInvalid) {
        // do nothing
      } else if (isNotNullOrUndefined(key) && isValidNumber(key) && Number(key) > -1) {
        isValidated = true;
      } else {
        isValidated = false;
        isAlreadyInvalid = true;
      }
    });
  }
  // console.log('validated?:', isValidated, keys);
  return isValidated;
}

export function stringToCurrency(data) {
  return new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR"
  }).format(validNumberOrDefault(data, 0));
}

export function stringToNumber(data, fixedDigits, defaultValue = 0) {
  if (isValidNumber(data)) {
    return fixedDigits ? Number(data).toFixed(fixedDigits) : Number(data);
  }

  return defaultValue;
}

export function camelCaseToTitleCase(stringText) {
  const result = stringText.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function numberFormat(data, fixedDigits, defaultValue = 0) {
  if (isValidNumber(data)) {
    data = Number(data).toFixed(fixedDigits);
    return new Intl.NumberFormat("en-ZA", {
      maximumSignificantDigits: 3
    }).format(validNumberOrDefault(data));
  }
  return defaultValue;
}

export function dataBasedFormatting(data, prefixString = "", suffixString = "", suffixSpacing = false) {
  if (isNullOrUndefined(data) || data.length === 0) {
    return "";
  }
  return `${validString(prefixString)} ${data}${suffixSpacing ? " " : ""}${validString(suffixString)}`.trim();
}

export function getDuration(startDate, endDate = new Date(), durationUOM = "second") {
  var momentStartDate = moment(startDate);
  var momentEndDate = moment(endDate);
  var duration = moment.duration(momentEndDate.diff(momentStartDate));

  if (!momentStartDate._isValid) {
    console.log({ momentStartDate, momentEndDate, duration });
    return null;
  }

  if (isNotNullOrUndefined(durationUOM)) {
    durationUOM = "second";
  }

  if (durationUOM.startsWith("sec")) {
    return duration.asSeconds();
  }

  if (durationUOM.startsWith("milli")) {
    return duration.asMilliseconds();
  }

  if (durationUOM.startsWith("min")) {
    return duration.asMinutes();
  }

  if (durationUOM.startsWith("d")) {
    return duration.asDays();
  }

  return duration.asSeconds();
}

export function durationDisplay(durationInSeconds, fullDisplay = true) {
  if (isValidNumber(durationInSeconds)) {
    setMomentThresholds();

    if (durationInSeconds > 0 && fullDisplay) {
      durationInSeconds = 0 - Number(durationInSeconds);
    }
    return moment.duration(durationInSeconds, "second").humanize(fullDisplay);
  }
  return "";
}

export function speedDisplay(speed, fullDisplay = true) {
  
  return `${speed} ${fullDisplay ? "km/h":""}`;
}

export function listFormatDisplay(list, prefix = "", suffix = "", suffixSpacing = false) {
  if (isValidArrayWithData) {
    list = list.filter((item) => item.length > 0);
    const formatter = new Intl.ListFormat("en", {
      style: "long",
      type: "conjunction"
    });

    const formattedList = formatter.format(list);

    return `${prefix} ${formattedList}${suffixSpacing ? " " : ""}${suffix}`.trim();
  }
  return "";
}

export function pluralOf(itemName, numberOfItems, excludeIfZeroOrUndefined = false) {
  if (numberOfItems === undefined && !excludeIfZeroOrUndefined) {
    return pluralize(itemName, 0);
  }

  if (isValidNumber(numberOfItems)) {
    if (excludeIfZeroOrUndefined && Number(numberOfItems) === 0) {
      return "";
    }
    return pluralize(itemName, numberOfItems, true);
  }
  if (excludeIfZeroOrUndefined) {
    return "";
  }

  return pluralize(itemName, numberOfItems);
}

export function commaSeparatedFormatDisplay(commaSeparatedString, suffix = "", prefix = "", suffixSpacing = false) {
  if (isNullOrUndefined(commaSeparatedString) || commaSeparatedString.length === 0) {
    return "";
  }

  return listFormatDisplay(commaSeparatedString.split(), suffix, prefix, suffixSpacing);
}

export function removePrePostQuotes(quotedString) {
  const typeOfData = typeof quotedString;
  let unquotedString = quotedString;

  if (isNullOrUndefined(quotedString)) {
    return quotedString;
  }
  if (typeOfData !== "string") {
    console.log("Not String", typeOfData, quotedString);
    return quotedString;
  }

  if (quotedString.startsWith("`") && quotedString.endsWith("`")) {
    unquotedString = quotedString.subString(1, quotedString.length - 2);
    console.log({ unquotedString, quotedString });
  }
  console.log(451, quotedString);
  return unquotedString;
}

export function distanceDisplay(distanceInMeters) {
  if (distanceInMeters >= 1000) {
    const distanceInKm = distanceInMeters / 1000;
    return distanceInKm.toFixed(2) + " km";
  } else {
    return distanceInMeters + " m";
  }
}

export function dateTimeMidnight(date) {
  return moment(date).isValid() ? moment(date).startOf("day").toDate() : date;
}

export function dateTimeDisplay(date, showInFull = false) {
  
  if (!moment(date).isValid()) {
    return "";
  }
  else if (showInFull) {
    return moment(date).format("DD MMM YYYY @ HH:mm:ss");  
  }
  
  return moment(date).format("DD MMM YYYY @ HH:mm");
}

export function dateDisplay(date) {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "";
}

export function dateMonthYearDisplay(date) {
  return moment(date).format("MMM 'YY");
}

export function dateDayMontDisplay(date) {
  return moment(date).format("DD MMM");
}

export function daysToSameDateLastMonth() {
  const today = new Date();
  const lastMonth = new Date(today);
  
  lastMonth.setMonth(today.getMonth() - 1);

  const timeDifference = today.getTime() - lastMonth.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return Math.round(daysDifference);
}

export function serviceBackgroundStageColor(stage) {
  switch (stage) {
    case "Un-allocated":
      return "bg-gray-300";
    case "Allocated":
      return "bg-gray-600";
    case "Awaiting Client Feedback":
      return "bg-red-400";
    case "In Progress":
      return "bg-blue-400";
    case "Awaiting Quotation":
      return "bg-yellow-400";
    case "Awaiting Payment":
      return "bg-yellow-400";
    case "Ticket Closed":
      return "bg-green-400";
    case "Unable To Close":
      return "bg-red-600";

    default:
      return "bg-orange-400";
  }
}

export function setLowerCase(data) {
  if (isNullOrUndefined(data)) {
    return data;
  }

  if (isValidNumber(data)) {
    return data;
  }

  if (isValidString(data)) {
    return data.toLowerCase();
  }

  return data;
}

// LODASH:  currently loading lodash locally in two functions, if needs be, this can be made global,
export function uniqueArray(arrayData) {
  var _ = require("lodash");
  return _.uniq(arrayData);
}

export function nearestInclusiveMaxArray(arrayData, maxValue) {
  var _ = require("lodash");

  if (!isValidArrayWithData(arrayData) || !isValidNumber(maxValue)) {
    return undefined;
  }

  let firstMax = maxValue - 1;
  const arrayMax = _.takeWhile(arrayData, (option) => {
    const shouldTake = maxValue >= option || firstMax <= maxValue;
    if (firstMax <= option) {
      firstMax = option + 1;
    }

    return shouldTake;
  });

  return arrayMax;
}

export function getNameFromUrlPath(urlPath) {
  if (isNullOrUndefined(urlPath)) {
    return "";
  }

  const splitPath = urlPath.split("/");
  const splitName = splitPath[0] ? splitPath[0] : splitPath[1];

  return titleCase(splitName);
}

export function objectPick(objectValue, keysToPick) {
  if (isNullOrUndefined(objectValue) || !isValidArrayWithData(keysToPick)) {
    return objectValue;
  }

  if (typeof objectValue !== 'object') { 
    return objectValue;
  }

  return keysToPick.reduce((picked, key) => {
 
    if (objectValue && objectValue.hasOwnProperty &&  objectValue.hasOwnProperty(key)) {
      picked[key] = objectValue[key];
    }
    return picked;
  }, {});
}

export async function getBrowserInfoByUserAgent() {
  const userAgent = window.navigator.userAgent;
  const brave = window.navigator.brave;
  const browserInfo = { browserName: "", linkInfo: "" };
  console.log({ userAgent });
  
  return new Promise((resolve) => {
    if (brave) {
      brave.isBrave().then(isBrave => {

        resolve({ browserName: `Brave${isBrave ? "" : "?"}`, linkInfo: "https://community.brave.com/t/is-it-possible-to-share-only-my-location-on-the-browser-and-sites/487159" });
        
      });
    } else {
      if (userAgent.includes('Firefox')) {
        browserInfo.browserName = 'Mozilla Firefox';
        browserInfo.linkInfo =  "https://timewellscheduled.com/eneable-location-sharing-on-firefox-and-google-chrome/";
      
      } else if (userAgent.includes('Edge') || userAgent.includes('Edg')) {
        setupBrowserInfo('Microsoft Edge', "enabling-microsoft-edge-location-services");
      
      } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        setupBrowserInfo('Apple Safari', "enabling-safari-location-services-mac");
  
      } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
        browserInfo.browserName = 'Opera';
        browserInfo.linkInfo =  "https://help.opera.com/en/latest/web-preferences/";
        
      } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
        browserInfo.browserName = 'Internet Explorer';
        browserInfo.linkInfo =  "https://support.microsoft.com/en-gb/windows/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe";
   
      } else if (userAgent.includes('Chrome')) {
        setupBrowserInfo('Google Chrome', "enabling-chrome-location-services");
     
      } else {
        setupBrowserInfo('Unknown Broswer', "");
      }
      console.log(browserInfo);
      resolve(browserInfo)
    }
  });

  function setupBrowserInfo(browserName,urlAnchorLinkTag, url = "") {
    const linkInfoWebsite = "https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting";

    browserInfo.browserName = browserName;
    browserInfo.linkInfo = `${linkInfoWebsite}#${urlAnchorLinkTag}`;
    
  }
}

export function removeDuplicates(arrayData, key) {

  return arrayData.filter((item, index, self) =>
    index === self.findIndex((t) => t[key] === item[key])
  );
}

export function getDeviceStatusColourScheme(){
return {
  Offline: "#ff0000",
  Inactive: "#808080",
  Online: "#008000",
  Unknown: "#FF00FF"
};
}

export function hasExpired(targetDateTime) {
  
  const targetDate = new Date(targetDateTime);

  // Get the current time
  const currentDate = new Date();

  // Compare currentDate with targetDate
  return currentDate.getTime() >= targetDate.getTime();
}
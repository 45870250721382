const UserRoleAccess = Object.freeze({
    deviceList: { name: "device", action: "list" },
    serviceOverviewList:{ name: "service", action: "overviewList" },
    serviceHubspotSynchronize: { name: "service", action: "hubspotSynchronize" },
    simCardList: { name: "simCard",action: "list" },
    simCardSynchronize: { name: "simCard", action: "synchronize" },
    userList: { name: "user", action: "list" },
    userCreate: { name: "user", action: "create" },
    userView: { name: "user", action: "view" },
    userManageRemoteLog: { name: "user", action: "manageRemoteLog" },
    canImportStdFuelAnalysis: { name: "fuelAnalysis", action: "importStd" },
    driverList: { name: "driver", action: "list" },
    vehicleAdd: { name: "vehicle", action: "add" },
    vehicleList: { name: "vehicle", action: "list" },
    vehicleSmsTrackCreate: { name: "vehicle", action: "smsTrackCreate" },
    vehicleSmsTrackList: { name: "vehicle", action: "smsTrackList" },
    vehicleMobilitySetClear: { name: "vehicle", action: "mobilitySetClear" },
    vehicleLinkDevice: {name: "vehicle", action: "linkDevice"}, 
    vehicleUnlinkDevice: { name: "vehicle", action: "unlinkDevice" }

}); 


export default UserRoleAccess
import { createSlice } from "@reduxjs/toolkit";
import { hasExpired, isValidNumber } from "../../core/utilities";

const initialState = {
  user: { isSignedIn: false },
  signOutMode: undefined,
  signIn: undefined,
  tokenExpired: false,
  refreshToken: {
    expired: undefined,
    attempts: 0,
    processStarted: undefined,
    processCompleted: undefined,
    processCompletedSuccessfully: undefined,
    processStartedDateTime: undefined,
    processCompletedDateTime: undefined,
  },
  refreshTokenExpired: false,
  refreshTokenAttempt: 0
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, id, designation, emailAddress, fullName, isRiiotAdmin, isRiiotUser, role, roleInfo, access, entity, tokenExpiry, refreshTokenExpiry, isRemoteLogEnabled, fleetInfo } = action.payload;
      const isSignedIn = isValidNumber(id) && accessToken && emailAddress && emailAddress.length > 3;
      const signInDateTime = isSignedIn ? new Date().toISOString() : undefined;
      // console.log("credentials set", { isSignedIn, tokenExpiry, refreshTokenExpiry });
      state.signIn = undefined;
      state.signOutMode = undefined;
      state.refreshToken = { attempts: 0,expired: hasExpired(refreshTokenExpiry) };
      state.tokenExpired = hasExpired(tokenExpiry);
      state.user = {
        isSignedIn,
        id,
        emailAddress,
        fullName,
        designation,
        isRiiotAdmin,
        isRiiotUser,
        role,
        roleInfo,
        access,
        entity,
        refreshTokenExpiry,
        tokenExpiry,
        token: accessToken,
        signInDateTime,
        isRemoteLogEnabled,
        fleetInfo
      };
    },
    setUserTokenFromRefresh: (state, action) => {
      const { accessToken, tokenExpiry, refreshTokenExpiry } = action.payload;
      const isSignedIn = isValidNumber(state.user.id) && accessToken && state.user.emailAddress && state.user.emailAddress.length > 3;
      // console.log("set UserToken", { isSignedIn, tokenExpiry, refreshTokenExpiry, accessToken });
      state.signIn = undefined;
      state.signOutMode = undefined;
      state.tokenExpired = hasExpired(tokenExpiry);
      state.refreshToken = {expired: hasExpired(refreshTokenExpiry), attempts: 0,processCompleted:true, processCompletedSuccessfully:true};
      state.user = { ...state.user, isSignedIn, refreshTokenExpiry, tokenExpiry, token: accessToken      };
    },
    clearSignInCredentials: (state) => {
      // console.log("credentials cleared");
      state.user = { isSignedIn: false };
      state.signIn = undefined;
    },
    setSystemSignOut: (state, action) => {
      const { status } = action.payload;
      state.signOutMode = { isUserSignOut: false, isSystemSignOut: true, status };
    },
    setUserSignOut: (state) => {
      state.signOutMode = { isUserSignOut: true };
    },
    setSignInFail: (state, action) => {
      const { emailAddress } = action.payload;
      const failedSignIn = state.signIn?.emailAddress === emailAddress ? state.signIn.failedSignIn + 1 : 1;
      state.signIn = { emailAddress: emailAddress, failedSignIn: failedSignIn };
    },
    clearSignInFail: (state) => {
      state.signIn = undefined;
    },
    validateTokenExpiry: (state) => {
      const { isSignedIn, refreshTokenExpiry, tokenExpiry } = state.user;

      if (isSignedIn) {
        state.refreshTokenExpired = hasExpired(refreshTokenExpiry);
        state.tokenExpired = hasExpired(tokenExpiry);
      }
    },
    setRefreshTokenAttempt: (state,action) => {
      state.refreshToken.refreshAttempts = action.payload.refreshTokenAttempt;
    },
    setRefreshTokenProcessStart: (state) => {
      state.refreshToken.processStarted = true;
      state.refreshToken.processCompleted = false;
      state.refreshToken.processCompletedSuccessfully = undefined;
      state.refreshToken.processStartedDateTime= new Date().getTime();
    },
    setRefreshTokenProcessCompleted: (state,action) => {
      state.refreshToken.processCompleted = true;
      state.refreshToken.processCompletedSuccessfully = action.payload;
      state.refreshToken.processStartedDateTime= new Date().getTime();
    }

  }
});

export const { setCredentials, clearSignInCredentials, setSystemSignOut, setUserSignOut,  setUserTokenFromRefresh, setSignInFail, setRefreshTokenAttempt,
  setRefreshTokenProcessStart,setRefreshTokenProcessCompleted,
  clearSignInFail, validateTokenExpiry } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentEmailAddress = (state) => state.auth.user.emailAddress;
export const selectCurrentEntity = (state) => state.auth.user.entity;
export const selectIsCurrentTokenExpired = (state) => {
  const { token, tokenExpiry } = state.auth.user;
  const tokenExpired = token && tokenExpiry ? hasExpired : true;

  if (tokenExpired && !state.tokenExpired) {
    state.tokenExpired = tokenExpired;

  }

  return tokenExpired;
};
